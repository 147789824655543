<template>
    <div class="about">
      <heade :current="['3']"></heade>
      <div style="padding:15px 7% 0;min-height:calc(100vh - 134px)">
        <!-- <car></car> -->
        <a-tabs default-active-key="1" :tab-position="tabPosition" @change="tabsFun">
        <a-tab-pane key="1" tab="组织结构1">
          <list :tabsNum="tabsNum" tabsName="组织结构1" ></list>
        </a-tab-pane>
        <a-tab-pane key="2" tab="组织结构2">
          <list :tabsNum="tabsNum" tabsName="组织结构2"></list>
        </a-tab-pane>
        <!-- <a-tab-pane key="3" tab="大赛英雄榜">
          <list :tabsNum="tabsNum" tabsName="大赛英雄榜"></list>
        </a-tab-pane>
        <a-tab-pane key="4" tab="大赛资源">
          <list :tabsNum="tabsNum" tabsName="大赛资源"></list>
        </a-tab-pane>
        <a-tab-pane key="5" tab="大赛官网">
          <list :tabsNum="tabsNum" tabsName="大赛官网"></list>
        </a-tab-pane> -->
      </a-tabs>
      </div>

    </div>
  </template>
  <script>
  import heade from "../components/heade.vue";
  import list from "../components/list.vue";

  
  // const data = [];
  // for (let i = 0; i < 100; i++) {
  //   data.push({
  //     key: i,
  //     name: `Edrward ${i}`,
  //     age: 32,
  //     address: `London Park no. ${i}`,
  //   });
  // }
  export default {
    data() {
      return {
        tabPosition:'left',
        tabsNum:'1'
       
      };
    },
    components: {
      heade,

      list
      // car
    },
    mounted() {
     
    },
    methods:{
      callback(val) {
        console.log(val);
      },
      tabsFun(val){
        console.log(val);
        this.tabsNum =val
      }
    }
  };
  </script>
  <style scoped>

.footer {
  background: #eee;
  padding: 30px 5%;
}
/deep/.ant-divider{
  background: #878787;
}</style>