<template>
  <div style="text-align: left; height: calc(100vh - 168px); overflow: auto">
    <a-breadcrumb class="bread">
      <a-breadcrumb-item>{{ bigName }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ tabsName }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div v-if="tabsUrl==''">
    <!--文章显示-->


    <a-list

      item-layout="horizontal"
      :data-source="dataCur"
      style="text-align: left"
      :pagination="pagination"
    >
      <a-list-item slot="renderItem" slot-scope="item, index"  @click="goTo(item.content,item.url)">
        <a-list-item-meta :description="item.intro">
          <a slot="title">
            <p>{{ item.title }}</p>
  

            <p
              style="
                color: rgba(0, 0, 0, 0.45);
                font-size: 14px;
                line-height: 22px;
              "
            >
      
            </p></a
          >
        
          
          <a-avatar slot="avatar" :src="item.imgpath" />
        </a-list-item-meta>
      </a-list-item>
    </a-list>
  </div>
  <div v-else style="height:calc(100vh - 200px)">
    <iframe :src="tabsUrl" style="width:100%;height:95%;border:none;margin-top: 20px;border-radius: 10px;"></iframe>
  </div>
    
  </div>
</template>
  <script>
  import dec from "./dec.vue";
  // import {xtnlds_dsdt,xtnlds_dszz,xtnlds_dsyxb,xtnlds_dszy,xtnlds_dsgw } from '../assets/comon.js'
export default {
  data() {
    return {
      components:{
        dec
      },
      data: [],
      dataCur:[],
      // xtnlds_dsdt,
      // xtnlds_dszz,
      // xtnlds_dsyxb,
      // xtnlds_dszy,
      // xtnlds_dsgw,
      bigName: "",

      pagination: {
        // onChange: (page) => {
        //   console.log('page',page);
        //   this.$emit('pageCuurenFun',page)
        // },
        // pageSize: 5,
        // total:0
      },
    };
  },
  props: {
    tabsNum: String,
    tabsName: String,
    listCon: [],
    tabsType:String,
    tabsUrl:String,
    recordcount:Number
  },
  watch:{
    tabsUrl(o,n){
      console.log(o,n)
    },
    listCon(o,n){
      console.log(o,n)
      this.dataCur= o;
      console.log('dataCur',this.dataCur)
    },
    recordcount(o,n){
      console.log(o,n)
      // this.pagination.total= o
      this.pagination= {
        onChange: (page) => {
          console.log('page',page);
          this.$emit('pageCuurenFun',page)
       
        },
        pageSize: 5,
        total:o
      }
    }
  },
  mounted() {
    console.log('this.tabsUrl',this.tabsUrl)
    console.log('this.tabsUrl',this.tabsUrl.length)
    console.log(this.listCon)
   
    // 静态数据
    // if(window.localStorage.getItem('xtnlds-anjing') == '系统能力大赛'){
    //   if(this.tabsType == '大赛动态'){
    //     this.dataCur = xtnlds_dsdt;
    //   }
    //   else if(this.tabsType == '大赛组织'){
    //     this.dataCur = xtnlds_dszz;
    //   }
    //   else if(this.tabsType == '大赛英雄榜'){
    //     this.dataCur = xtnlds_dsyxb;
    //   }
    //   else if(this.tabsType == '大赛资源'){
    //     this.dataCur = xtnlds_dszy;
    //   }
    //   else if(this.tabsType == '大赛官网'){
    //     this.dataCur = xtnlds_dszz;
    //   } 
    // }
    // else if(window.localStorage.getItem('xtnlds-anjing') == '关于系统能力'){
    //   if(this.tabsType == '专家组成员'){
    //     this.dataCur = xtnlds_dsdt;
    //   }
    //   else if(this.tabsType == '大赛组织'){
    //     this.dataCur = xtnlds_dszz;
    //   }
    //   else if(this.tabsType == '大赛英雄榜'){
    //     this.dataCur = xtnlds_dsyxb;
    //   }
    //   else if(this.tabsType == '大赛资源'){
    //     this.dataCur = xtnlds_dszy;
    //   }
    //   else if(this.tabsType == '大赛官网'){
    //     this.dataCur = xtnlds_dszz;
    //   } 
    // }
 
    
    // else {
    //   this.dataCur = this.listCon;
    // }
    
    this.bigName = window.localStorage.getItem("xtnlds-anjing");
    typeof this.tabsNum;
    if (this.tabsNum == "1") {
      this.data = [
        {
          img: require("../assets/xtnl-001.jpg"),
          title: "2022全国大学生计算机系统能力大赛 CPU设计赛章程",
          description: "浏览：432 | 发布：2022-06-01",
        },
        {
          img: require("../assets/xtnl-002.jpeg"),
          title: "2022全国大学生计算机系统能力大赛 编译系统设计赛章程",
          description: "浏览：234 | 发布：2022-06-01",
        },

        {
          img: require("../assets/xtnl-003.png"),
          title:
            "2021全国大学生计算机系统能力大赛操作系统设计大赛 决赛入围名单暨优胜奖获得者",
          detail:
            "2021全国大学生计算机系统能力大赛操作系统设计大赛 决赛入围名单暨优胜奖获得者",
          description: "浏览：1234 | 发布：2022-06-21",
        },
        {
          img: require("../assets/xtnl-004.jpg"),
          title: "2021年全国大学生计算机系统能力培养大赛 报名启动",
          detail:
            "全国大学生计算机系统能力大赛（以下简称“大赛”）是由教育部高等学校计算机类专业教学指导委员会和系统能力培养研究专家组共同发起，以学科竞赛推动专业建设和计算机领域创新人才培养体系改革、培育我国高端芯片及核心系统的技术突破与产业化后备人才为目标，面向高校大学生举办的全国性大赛。大赛鼓励学生设计、实现一个综合性的计算机系统及基础软件，培育未来我国计算机系统相关领...",
          description: "浏览：734 | 发布：2022-08-26",
        },
        {
          img: require("../assets/xtnl-005.png"),
          title: "百舸争流，鹰击长空，第四届“龙芯杯”圆满落幕",
          detail:
            "第四届“龙芯杯”全国大学生计算机系统能力培养大赛颁奖典礼于2020年8月21日在南京举行。颁奖典礼由清华大学刘卫东教授主持；教育部高等学校计算机类专业教学指导委员会秘书长、清华大学计算机系教授武永卫，全国高等学校计算机教育研究会理事长、国防科技大学教授王志英，大会承办方西北工业大学教授王党辉，中国互联网发展基金会副秘书长陈雷，中国计算机学会体系结构专委会主任武成岗...",
          description: "浏览：3234 | 发布：2022-08-26",
        },
        {
          img: require("../assets/xtnl-006.png"),
          title:
            "直播活动：全国大学生系统能力大赛编译系统设计赛（华为毕昇杯）技术培训",
          detail:
            "直播活动：全国大学生系统能力大赛编译系统设计赛（华为毕昇杯）技术培训直播主题：· 程序设计语言核心概念和关键技术分享· 编译大赛初赛回顾与决赛解读直播时间：8月8日 10:00-12:00主讲人介绍主题一：冯新宇2007年于耶鲁大学获博士学位，现任南京大学教授、华为2012中央软件院编程语言实验室主任。主要研究方向为程序设计语言理论、形式化程序验证等。提出并发程序模块化精化验证理...",
          description: "浏览：478 | 发布：2022-08-07",
        },
      ];
    } else if (this.tabsNum == "2") {
      this.data = [
        {
          img: require("../assets/xtnl-008.png"),
          title: "大赛组织",
          detial:
            "指导单位：中国互联网发展基金会主办单位：教育部高等学校计算机类专业教学指导委员会承办单位：青海大学、清华大学协办单位：龙芯中科技术有限公司        赛灵思（Xilinx）公司        Wave Computing,Inc        中国计算机学会体系结构专委会        机械工业出版社华章分社大赛技术支持与培训：龙芯中科技术有限公司",
          description: "浏览：127 | 发布：2019-06-01",
        },
      ];
    } else if (this.tabsNum == "3") {
      this.data = [
        {
          img: require("../assets/xtnl-009.jpg"),
          title: "第二届“龙芯杯”全国大学生计算机系统能力培养大赛英雄榜",
          detail:
            "特等奖：中国科学院大学一等奖：南京大学2队、西北工业大学1队、清华大学1队。二等奖 ：西北工业大学2队、北京航空航天大学2队、济南大学1队、华中科技大学1队、天津大学1队、北京航空航天大学1队。三等奖：南京航空航天大学1队、华中科技大学2队、河北大学1队、哈尔滨工业大学（威海）1队、北京理工大学1队、北京科技大学1队、重庆大学2队、中国科学院大学2队、东北大学1队、南京理工大...",
          description: "浏览：432 | 发布：2022-06-01",
        },
      ];
    } else if (this.tabsNum == "4") {
      this.data = [
        {
          img: require("../assets/xtnl-008.png"),
          title:
            "资料：全国大学生系统能力大赛编译系统设计赛（华为毕昇杯）技术培训（第一场）",
          detail:
            "主题：编译优化技术概览AIoT时代的编程语言、编译器与指令集架构全国大学生系统能力大赛编译系统设计赛技术方案解读主讲人介绍陈文光清华大学计算机系教授，兼任青海大学计算机系主任。主要研究领域为操作系统、程序设计语言与并行计算。获国家科技进步二等奖一次，部级科技一等奖两次。现为中国计算机学会杰出会员和杰出讲者，副秘书长，青年科技论坛荣誉委员；ACM中国理事会共同主席。...",
          description: "浏览：432 | 发布：2022-06-01",
        },
        {
          img: require("../assets/xtnl-110.jpg"),
          title: "第三届系统能力大赛第一次培训资料",
          detail:
            "参赛指南，大赛技术方案解读，功能性能测试和调试说明，汇编与verilog，MIPS指令集与CPU设计",
          description: "浏览：2341 | 发布：2022-06-01",
        },
      ];
    } else if (this.tabsNum == "5") {
      this.data = [
        {
          img: require("../assets/xtnl-009.jpg"),
          title: "2022全国大学生计算机系统能力大赛 CPU设计赛章程5",
          detail: "",
          description: "浏览：432 | 发布：2022-06-01",
        },
        {
          img: require("../assets/xtnl-002.jpeg"),
          title: "大赛官网",
          detail: "https://compiler.educg.net",
          description: "浏览：234 | 发布：2022-06-01",
        },
      ];
    }
  },
  methods:{
    goTo(content,url){
      debugger
      if(url !=''){

  
      //   let routeUrl = this.$router.resolve({
      //   path: url,
      // });
      // console.log(routeUrl.href);
      // console.log(routeUrl);
      // // window.location.href=routeUrl.href.split("#/")[1]
      // window.open(routeUrl.href.split("#/")[1], "_blank");
      window.open('//'+url)

      }
      else {
        window.localStorage.setItem('anjing-detail-new',content)
        this.$router.push({path:'./common',query:{id:window.localStorage.getItem("xtnlds-anjing-index")}})
       
      }
      
    }
  }
};
</script>
  <style scoped>
.bread {
  background: #ffeed5;
  padding: 5px 10px;
  border-radius: 7px;
}
/deep/.ant-avatar {
  width: 150px;
  height: 90%;
  border-radius: 10px;
}
</style>
  