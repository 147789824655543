<template>
    <div style="text-align: left;height: calc(100vh - 168px);overflow: auto;">
    <a-breadcrumb class="bread">
      <a-breadcrumb-item>{{ bigName }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ tabsName }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-timeline>
      <a-timeline-item>
        <h2 style="color:#3d9ded">2018-09-14</h2>
        <h3>第二届“龙芯杯”全国大学生计算机系统能力培养大赛总决赛胜利闭幕</h3>
        <p>本次大赛由中国互联网发展基金会指导，由教育部高等学校计算机类专业教学指导委员会主办，由南京大学承办，龙芯中科技术有限公司、赛灵思(Xilinx)公司、中国计算机学会体系结构专委会、机械工业出版社华章分社共同协办。</p>
      </a-timeline-item>
      <a-timeline-item>
        <h2 style="color:#3d9ded">2018-08-31</h2>
        <h3>2018中国核心技术创新发展峰会于南京成功举办</h3>
        <p>8月31日下午，在南京国际博览会议中心扬子厅举办了“2018中国核心技术创新发展峰会”，本次会议主题为“国产CPU及嵌入式OS应用”。会议由南京市人民政府主办，南京软件谷、龙芯中科技术有限公司、南京翼辉信息技术有限公司承办。</p>

      </a-timeline-item>
      <a-timeline-item>   
        <h2 style="color:#3d9ded">2018-07-30</h2>
        <h3>2018年“计算机体系结构”课程教导班火爆开班</h3>
        <p>由教育部高等学校计算机类专业教学指导委员会、机械工业出版社华章分社、龙芯中科联合举办的“2018年计算机体系结构课程导教班”于2018年7月30日在北京科技大学正式开班。</p></a-timeline-item>
      <a-timeline-item>  
         <h2 style="color:#3d9ded">2018-05-14</h2>
        <h3>第四届全国高校计算机专业系统能力培养高峰论坛在长沙举行</h3>
        <p>5月11日至13日，第四届高等院校计算机类专业系统能力培养高峰论坛在湖南长沙举行，来自国内计算机领域的近300名专家学者和企业人士参加会议。本届论坛由国家教育部高等学校计算机类专业教学指导委员会（以下简称“教指委”）主办，国防科技大学承办，高等教育出版社、机械工业出版社协办。</p></a-timeline-item>

<a-timeline-item>
         <h2 style="color:#3d9ded">2017-05-06</h2>
        <h3>第三届全国高校计算机系统能力培养高峰论坛举行暨第一届全国大学生系统能力培养大赛（龙芯杯）正式启动</h3>
        <p>5月6日，第三届全国高校计算机系统能力培养高峰论坛在南开大学省身楼举行，来自全国各地计算机领域的近300名专家学者、企业人士及高校师生代表共同出席。</p>
    </a-timeline-item>
    </a-timeline>


   
</div>
  </template>
  <script>
export default {
  props: {
    tabsNum: String,
    tabsName: String,
  },
  data() {
    return {
      bigName: "",
    };
  },
  mounted() {
    this.bigName = window.localStorage.getItem("xtnlds-anjing");
  },
};
</script>
    <style scoped>
.bread {
  background: #ffeed5;
  padding: 5px 10px;
  border-radius: 7px;
  margin-bottom: 20px;
}
</style>