<template>
  <div class="about">
    <heade :current="['2']"></heade>
    <div style="padding: 15px 7% 0;min-height:calc(100vh - 134px)">
      <!-- <car></car> -->
      <a-tabs
        default-active-key="1"
        :tab-position="tabPosition"
        @change="tabsFun"
      >
        <a-tab-pane key="1" tab="项目介绍">
          <!-- <list :tabsNum="tabsNum" tabsName="项目介绍" ></list> -->
          <div style="text-align: left" class="right-text">
            <a-breadcrumb class="bread">
      <a-breadcrumb-item>{{ bigName }}</a-breadcrumb-item>
      <a-breadcrumb-item>项目介绍</a-breadcrumb-item>
    </a-breadcrumb>
            <h3 >“系统能力培养——专业课程体系改革及课程建设”项目介绍</h3>
            <div style="margin: 35px 0 20px">浏览：5985 发布日期：2019-04-30</div>
            <p>
              2010年，教育部高等学校计算机类专业教学指导会组织清华大学、北京大学、北京航空航天大学、国防科学技术大学、南京大学、上海交通大学、浙江大学、中国科学技术大学、西北工业大学、南开大学等高校的资深计算机教育专家，并联合机械工业出版华章分社成立“计算机类专业系统能力培养研究专家组”，研究组经过对国内外计算机专业的课程体系、用人单位的需求及技术的发展，提出了“计算机类专业系统能力培养专业课程体系”（如下图所示）。
            </p>
            <p>
              该课程体系以培养计算机专业学生系统能力为目标，通过新增（如新增“计算机系统基础”课程）、重组或改造传统课程（如第二层次课程）、拓展和开发与新型计算技术结合的新课程（如第三层课程），使学生能够全面、深入地理解计算机系统，具备解决复杂工程问题的能力。
            </p>
            <p>
              教指委鼓励各高校结合自身定位与特色，探索并实践适合本校学生的系统能力培养课程体系或课程改革方案。在具体实施上，可采用在计算机类专业中遴选一定规模的学生，按照上述课程体系或课程项目，遵循系统能力培养的相关要求，结合本校实际，选择合适的实施方案开展教学改革工作。待经验成熟后，再推广至全部学生。
            </p>
            <p>
              教指委以各种措施支持高校进行系统能力培养改革。2016~2018年，教指委启动了三批系统能力培养改革试点校的申报工作，甄选出出清华大学等8所示范校，哈尔滨工业大学等76所试点校。同时，“计算机类专业系统能力培养研究项目专家组”通过各种方式对进行系统能力培养改革的高校提供全程咨询与指导，并通过组织教师培训、教学研讨会、专家对接、校际交流及教师交流、校企交流等形式帮助高校推进改革。针对上述实施方案，专家组将开发对应的课程示例、教学资源和教材，供试点单位参考。对于改革成果显著的高校，专家组将通过相关渠道推广改革成果，并优先推荐其申报教育部产学改革项目。
            </p>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="专家组成员">
          <car-img :tabsNum="tabsNum" tabsName="专家组成员"></car-img>
        </a-tab-pane>
        <a-tab-pane key="3" tab="试点院校">
          <card-list :tabsNum="tabsNum" tabsName="试点院校"></card-list>
        </a-tab-pane>
        <a-tab-pane key="4" tab="新工科项目">
            <div style="text-align: left" class="right-text">
            <a-breadcrumb class="bread">
      <a-breadcrumb-item>{{ bigName }}</a-breadcrumb-item>
      <a-breadcrumb-item>新工科项目</a-breadcrumb-item>
    </a-breadcrumb>
            <!-- <h3 >“系统能力培养——专业课程体系改革及课程建设”项目介绍</h3>
            <div style="margin: 35px 0 20px">浏览：5985 发布日期：2019-04-30</div> -->
            <p>
               计算机类专业学生的系统能力核心是在掌握计算系统基本原理基础上，深入地掌握计算系统内部各软件/硬件部分的关联关系与逻辑层次，了解计算系统呈现的外部特性以及与人和物理世界的交互模式，开发构建以计算技术为核心的高效应用系统。系统能力包括系统知识和工程实践能力。系统知识是掌握计算机核心系统的工作原理、构造方法和软硬件相互协同关系，工程实践能力是用工程方法开发计算机应用系统。系统能力的培养具有突出的工程教育特征，是解决复杂工程问题的直接体现。与其他专业学生的计算机基础和应用能力相比，计算机类专业学生应突出强调计算机系统能力的培养，系统设计、应用和创新能力必须得到强化与提升。           
            </p>
               <p>
              该课程体系以培养计算机专业学生系统能力为目标，通过新增（如新增“计算机系统基础”课程）、重组或改造传统课程（如第二层次课程）、拓展和开发与新型计算技术结合的新课程（如第三层课程），使学生能够全面、深入地理解计算机系统，具备解决复杂工程问题的能力。
            </p>
            <p>
                目前，国内高校计算机类专业教学对系统能力培养的研究改革和重视程度不够，因而培养的学生在系统能力方面存在一些问题，不能满足社会、学科技术发展和用人单位的要求。首先，不能很好地建立计算机系统完整概念，缺乏系统观，对于系统层面问题的解决无法胜任；第二，对于计算机系统的核心内容掌握不够，大部分学生难以胜任复杂的涉及到软/硬件协同设计的任务；第三，由于没有很好地建立课程之间内容的关联，使得学生综合分析、设计和应用能力也较差；第四，系统性的综合实践环节的缺乏，学生系统实践动手能力较差。
            </p>
            <p>
                本项目的工作目标是改造升级一批高等学校传统计算机类专业，加强新工科计算机类专业系统能力培养，推动高新技术与计算机类专业的知识、能力、素质要求深度融合，探索计算机类专业改造升级的实施路径，提高学生适应新经济发展的整体素质和能力。
            </p>
          </div>
          <!-- <list :tabsNum="tabsNum" tabsName="新工科项目"></list> -->
        </a-tab-pane>
        <a-tab-pane key="5" tab="大事记">
          <timeLine :tabsNum="tabsNum" tabsName="大事记"></timeLine>
        </a-tab-pane>
      </a-tabs>
    </div>

  </div>
</template>
  <script>

import heade from "../components/heade.vue";
import list from "../components/list.vue";
import carImg from "../components/carImg.vue";
import cardList from "../components/cardList.vue";
import timeLine from "../components/timeLine.vue";



// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i,
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }
export default {
  data() {
    return {
      tabPosition: "left",
      tabsNum: "1",
      bigName:''
    };
  },
  components: {
    heade,
    list,
    carImg,
    cardList,
    timeLine,
 
    // car
  },
  mounted() {
    this.bigName = window.localStorage.getItem("xtnlds-anjing");
  },
  methods: {
    callback(val) {
      console.log(val);
    },
    tabsFun(val) {
      console.log(val);
      this.tabsNum = val;
    },
  },
};
</script>
  <style scoped>
.right-text p {
  text-indent: 2em;
  color: #000;
  line-height: 30px;
  font-size: 16px;
}
.bread {
  background: #ffeed5;
  padding: 5px 10px;
  border-radius: 7px;
  margin-bottom:20px;
}
.footer {
  background: #eee;
  padding: 30px 5%;
}
/deep/.ant-divider{
  background: #878787;
}
</style>