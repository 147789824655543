<template>
    <div class="about">
      <heade :current="['4']"></heade>
      <div style="padding:15px 7% 0; min-height:calc(100vh - 134px)">
        <!-- <car></car> -->
        <a-tabs default-active-key="1" :tab-position="tabPosition" @change="tabsFun">
        <a-tab-pane key="1" tab="系统能力活动新闻">
          <list-xw :tabsNum="tabsNum" tabsName="系统能力活动新闻" ></list-xw>
        </a-tab-pane>
        <a-tab-pane key="2" tab="教育政策类新闻">
          <list-xw :tabsNum="tabsNum" tabsName="教育政策类新闻"></list-xw>
        </a-tab-pane>
    
      </a-tabs>
      </div>
      <!-- <div class="footer">
    <a href="https://beian.miit.gov.cn/ " target="_blank">豫ICP备18015569号-19  <a-divider type="vertical" /> 豫ICP备18015569号-18  <a-divider type="vertical" /> 豫ICP备18015569号-17  <a-divider type="vertical" /> 豫ICP备18015569号-20  <a-divider type="vertical" /> 豫ICP备18015569号-16</a>
  </div> -->

    </div>
  </template>
  <script>
  import heade from "../components/heade.vue";
  import listXw from "../components/listXw.vue";

  
  // const data = [];
  // for (let i = 0; i < 100; i++) {
  //   data.push({
  //     key: i,
  //     name: `Edrward ${i}`,
  //     age: 32,
  //     address: `London Park no. ${i}`,
  //   });
  // }
  export default {
    data() {
      return {
        tabPosition:'left',
        tabsNum:'1'
       
      };
    },
    components: {
      heade,
      listXw,
     
      // car
    },
    mounted() {
     
    },
    methods:{
      callback(val) {
        console.log(val);
      },
      tabsFun(val){
        console.log(val);
        this.tabsNum =val
      }
    }
  };
  </script>
  <style scoped>
.footer {
  background: #eee;
  padding: 30px 5%;
}
/deep/.ant-divider{
  background: #878787;
}</style>