<template>
  <div class="about">
    <div style="padding: 15px 7% 0; min-height: calc(100vh - 132px)">
      <!-- <car></car> -->
      <a-tabs
        default-active-key="1"
        v-model="defValue"
        :tab-position="tabPosition"
        @change="tabsFun"
      >
        <a-tab-pane
          :key="index + 1 + ''"
          :tab="item.title"
          v-for="(item, index) in listAry"
        >
    <!---里面可以显示不同的样式-->
          <list
            :tabsType="item.title"
            :tabsNum="tabsNum"
            :tabsName="tabsName"
            :listCon="listCon"
            :tabsUrl="tabsUrl"
            :recordcount = 'recordcount'
            @pageCuurenFun="pageCuurenFun"
          ></list>
        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="大赛组织">
        <list :tabsNum="tabsNum" tabsName="大赛组织"></list>
      </a-tab-pane>
      <a-tab-pane key="3" tab="大赛英雄榜">
        <list :tabsNum="tabsNum" tabsName="大赛英雄榜"></list>
      </a-tab-pane>
      <a-tab-pane key="4" tab="大赛资源">
        <list :tabsNum="tabsNum" tabsName="大赛资源"></list>
      </a-tab-pane>
      <a-tab-pane key="5" tab="大赛官网">
        <list :tabsNum="tabsNum" tabsName="大赛官网"></list>
      </a-tab-pane> -->
      </a-tabs>
    </div>

  </div>
</template>
<script>
// import heade from "../components/heade.vue";
import list from "../components/list.vue";


// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i,
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }
export default {
  data() {
    return {
      tabPosition: "left",
      tabsNum: "1",
      currentTab: ["1"],
      listAry: [],
      listCon: [],
      changeValue: window.localStorage.getItem("xtnlds-anjing"),
      defValue: "1",
      tabsName:'',
      tabsUrl:'',
      recordcount:0,
      currentId:''
    };
  },
  components: {
    // heade,
    list,

    // dec
    // car
  },
  mounted() {
    if (
      window.localStorage.getItem("xtnlds-anjing-rel") &&
      window.localStorage.getItem("xtnlds-anjing-rel") != ""
    ) {
      // 首页跳转过来的

      window.localStorage.setItem("xtnlds-anjing-rel", "");
      location.reload();
    }
    debugger
    if (window.localStorage.getItem("xtnlds-anjing-ej-tab") && window.localStorage.getItem("xtnlds-anjing-ej-tab")!='') {
      console.log(window.localStorage.getItem("xtnlds-anjing-ej-tab"))
      this.defValue = window.localStorage.getItem("xtnlds-anjing-ej-tab")
    }
    else {
      this.defValue  = '1'
    }
    this.testinit();
    // this.currentTab[0]=window.localStorage.getItem('xtnlds-anjing-index');
  },
  watch: {
    changeValue(o, n) {
      console.log(o, n);
    },
  },
  methods: {
    // currentTabFun(val){
    //   this.currentTab = val;
    //   console.log(this.currentTab)
    // },
    testinit() {
      let data = {
        op: "tablist",
        title: window.localStorage.getItem("xtnlds-anjing"),
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.listAry = response.data.data;
            debugger
            _this.tabsName = response.data.data[_this.defValue-1 ].title
            debugger
            _this.conFun(response.data.data[_this.defValue-1]._id);
            _this.currentId = response.data.data[_this.defValue-1]._id
           
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    pageCuurenFun(page){
      console.log('opopo')
      this.conFun(this.currentId,page)

    },
    conFun(_id,page=1) {
      let data = {
        op: "newslist",
        _id: _id,
        page:page,
        limit:5
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/news.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          debugger
          if (response.data.value == "ok") {
           
         
            if(response.data.url){
              _this.tabsUrl = response.data.url;
              _this.listCon =[]
            }
            else {
              _this.tabsUrl=[]
              _this.listCon = response.data.data;
              _this.recordcount = response.data.recordcount
              console.log('response.data.recordcount',response.data.recordcount)
              // console.log(listCon)
            }
          
      
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    callback(val) {
      console.log(val);
    },
    tabsFun(val) {
      console.log('tttttttttttt',val);
      this.tabsNum = val;
      window.localStorage.setItem('xtnlds-anjing-ej-tab',val)
      this.tabsName = this.listAry[this.defValue-1 ].title
      this.conFun(this.listAry[val - 1]._id);
      this.currentId = this.listAry[val - 1]._id
    },
  },
};
</script>
<style scoped>
.footer {
  background: #eee;
  padding: 30px 5%;
}
/deep/.ant-divider {
  background: #878787;
}
</style>