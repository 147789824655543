<template>
  <div style="text-align: left;height: calc(100vh - 168px);overflow: auto;">
    <a-breadcrumb class="bread">
      <a-breadcrumb-item>{{ bigName }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ tabsName }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-list
      item-layout="horizontal"
      :data-source="data"
      style="text-align: left"
      :pagination="pagination"
    >
      <a-list-item slot="renderItem" slot-scope="item, index">
        <a-list-item-meta :description="item.description">
          <a slot="title" >
            <p>            {{ item.title }}</p>

            <p style="color: rgba(0, 0, 0, 0.45);font-size: 14px;line-height: 22px;">
              {{ item.detail }}
            </p></a
          >
          <a-avatar slot="avatar" :src="item.img" />
        </a-list-item-meta>
      </a-list-item>
    </a-list>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      data: [],
      bigName: "",
      pagination: {
        onChange: page => {
          console.log(page);
        },
        pageSize: 5,
      },
    };
  },
  props: {
    tabsNum: String,
    tabsName: String,
  },
  mounted() {
    this.bigName = window.localStorage.getItem("xtnlds-anjing");
    typeof this.tabsNum;
    if (this.tabsNum == "1") {
      this.data = [
        {
          img: require("../assets/xw-001.jpg"),
          title: "无惧挑战：2022全国大学生计算机系统能力大赛操作系统设计赛OS功能挑战赛一等奖赛队获奖感言",
          detial:'OS功能挑战赛一等奖赛队获奖感言。',
          description: "浏览：432 | 发布：2022-06-01",
        },
        {
          img: require("../assets/xw-002.jpg"),
          title: "圆满收官：2022全国大学生计算机系统能力大赛操作系统设计赛举行总决赛颁奖典礼",
          detail:'快来看看一等奖赛队获奖感言吧！',
          description: "浏览：234 | 发布：2022-06-01",
        },

        {
          img: require("../assets/xw-003.jpg"),
          title:
            "2022年全国大学生计算机系统能力大赛编译系统设计赛（华为毕昇杯）​优秀指导老师名单！",
          detail:
            "编译系统设计赛优秀指导教师单公布。",
          description: "浏览：1234 | 发布：2022-06-21",
        },
 
      ];
    } else if (this.tabsNum == "2") {
      this.data = [
        {
          img: require("../assets/xw-004.jpg"),
          title: "河北大学网络空间与计算机学院成功举办“直隶大讲堂：新工科背景下的计算机系统能力培养“报告会",
          detial:'2018年8月25日，为探讨新工科背景下的计算机系统能力培养，“直隶大讲堂：新工科背景下的计算机系统能力培养”报告会在河北保定成功举办。本次会议由中国计算机学会（CCF）主办，我院承办。特邀专家学者、全国各地高校计算机院系领导、知名IT企业高管、科研院所研究人员以及政府机关事业单位领导等一百余名代表参会。在开幕式上，保定市人民政府副秘书长张墀、河北大学副校长康书生教授...',
          description: "浏览： 180   |   发布：2019-05-10",
        },
        {
          img: require("../assets/xw-005.jpg"),
          title: "西安邮电大学获批教育部计算机类专业系统能力培养试点高校",
          detial:'5月11日至13日，由教育部高等院校计算机类专业教学指导委员会、全国高等学校计算机教育研究会、中国计算机学会教育专业委员会联合主办的第四届高等院校计算机类专业系统能力培养高峰论坛在长沙举行。经过认真准备、积极申报，西安邮电大学获批教育部高等学校计算机类专业教学指导委员会“系统能力培养”项目第三批试点高校。我校计算机学院代表与来自全国各地计算机领域的专家学者、企业...',
          description: "浏览：127 | 发布：2019-06-01",
        },
 
      ];
    }
  },
};
</script>
  <style scoped>
.bread {
  background: #ffeed5;
  padding: 5px 10px;
  border-radius: 7px;
}
/deep/.ant-avatar {
  width: 210px;
  height: 90%;
  border-radius: 10px;
}
</style>
  