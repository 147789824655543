<template>
    <div class="about">
        <!-- <div v-html="content" style="min-height:calc(100vh - 134px)"></div> -->
        <VueMarkdown :source="content"  class="articalContent markdown-body " style="min-height:calc(100vh - 134px);padding: 15px 7% 0px;"></VueMarkdown>
    </div>
  </template>
  <script>
  import VueMarkdown from 'vue-markdown'
  export default {
      components:{
        VueMarkdown
      },
      data(){
        return {
            content:''
        }
      },
    mounted() {
        debugger
        this.content=window.localStorage.getItem('anjing-detail-new')
        console.log(this.content)
  

  
    },
  };
  </script>
  <style>
  @import '../css/markdown.css';
  .markdown-body img{
    /* width:100%; */
  }
  .about{
    width:100%;
    margin:0 auto
  }
  </style>