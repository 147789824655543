<template>
  <div style="text-align: left" class="right-text">
    <a-breadcrumb class="bread">
      <a-breadcrumb-item>{{ bigName }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ tabsName }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-card :title="item.title" v-for="(item, index) in data" :key="index">
      <a-card-grid
        style="width: 25%; text-align: center"
        v-for="(y, i) in item.list"
        :key="i"
      >
        <div style="display: flex; justify-content: space-between;text-align:left">
          <a-avatar :size="64" :src="y.img" style="width: 20%" />
          <div style="width: 70%">
            <h3>{{ y.title }}</h3>
            <p>{{ y.dec }}</p>
          </div>
        </div>
      </a-card-grid>
    </a-card>
  </div>
</template>
  <script>
export default {
  props: {
    tabsNum: String,
    tabsName: String,
  },
  data() {
    return {
      bigName: "",
      data: [
        {
          title: "系统能力培养示范高校名单",
          list: [
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg",
              title: "清华大学",
              dec: "负责人刘卫东",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg",
              title: "南京大学",
              dec: "负责人：袁春风",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286497792714.jpg",
              title: " 中国科学技术大学",
              dec: "负责人：安虹",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg",
              title: "清华大学",
              dec: "负责人刘卫东",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg",
              title: "南京大学",
              dec: "负责人：袁春风",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286132868503.jpg",
              title: "  国防科学技术大学",
              dec: "负责人：陆洪毅",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg",
              title: "南京大学",
              dec: "负责人：袁春风",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286497792714.jpg",
              title: " 中国科学技术大学",
              dec: "负责人：安虹",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg",
              title: "清华大学",
              dec: "负责人刘卫东",
            },
          ],
        },
        {
          title: "系统能力培养试点高校名单",
          list: [
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg",
              title: "清华大学",
              dec: "负责人刘卫东",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg",
              title: "南京大学",
              dec: "负责人：袁春风",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286497792714.jpg",
              title: " 中国科学技术大学",
              dec: "负责人：安虹",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg",
              title: "清华大学",
              dec: "负责人刘卫东",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg",
              title: "南京大学",
              dec: "负责人：袁春风",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286132868503.jpg",
              title: "  国防科学技术大学",
              dec: "负责人：陆洪毅",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg",
              title: "清华大学",
              dec: "负责人刘卫东",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg",
              title: "南京大学",
              dec: "负责人：袁春风",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286132868503.jpg",
              title: "  国防科学技术大学",
              dec: "负责人：陆洪毅",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570285826696065.jpg",
              title: "清华大学",
              dec: "负责人刘卫东",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286220222552.jpg",
              title: "南京大学",
              dec: "负责人：袁春风",
            },
            {
              img: "https://csc-he.cn/Uploads/ContentItem/2021/05/19/637570286132868503.jpg",
              title: "  国防科学技术大学",
              dec: "负责人：陆洪毅",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.bigName = window.localStorage.getItem("xtnlds-anjing");
  },
};
</script>

  <style scoped>
.bread {
  background: #ffeed5;
  padding: 5px 10px;
  border-radius: 7px;
  margin-bottom: 20px;
}
/deep/ .ant-card-bordered {
  margin-bottom: 30px;
}
/deep/.ant-card-head {
  background: #d3edff;
}
</style>