<template>
  <div class="about">
  </div>
</template>
<script>

export default {
    components:{

    },
  mounted() {

this.$router.push({
      path: "./about",
      query: { id: window.localStorage.getItem("xtnlds-anjing-index") },
    });


  },
};
</script>