<template>
  <div style="text-align: left;height: calc(100vh - 168px);overflow: auto;">
    <a-breadcrumb class="bread">
      <a-breadcrumb-item>{{ bigName }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ tabsName }}</a-breadcrumb-item>
    </a-breadcrumb>
  <a-carousel arrows dots-class="slick-dots slick-thumb">
    <a slot="customPaging" slot-scope="props">
      <img :src="getImgUrl(props.i)" />
    </a>
    <div v-for="(item, index) in list" :key="index" >
        <div style="display:flex;justify-content: space-between;">
      <a-avatar
      style="    width:15%;height:100%"        src="https://img0.baidu.com/it/u=4275172878,2244226890&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500"
      />
      <div style="width:80%">
        <h3 style="margin-top:20px">{{ item.name }}</h3>
        <p style="color:#c04700">{{ item.title }}</p>
        <p style="text-align: left;">{{ item.dec }}</p>
      </div>
    </div>
    </div>
  </a-carousel>
</div>
</template>
  <script>
const baseUrl =
  "https://img0.baidu.com/it/u=4275172878,2244226890&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500";
export default {
    props: {
    tabsNum: String,
    tabsName: String,
  },
  data() {
    return {
        bigName:'',
      baseUrl,
      list: [
        {
          img: "",
          name: "老师一",
          title: "西北工业大学教授，博士生导师",
          dec: "教育部高等学校计算机类专业教学指导委员“智能时代计算机类专业教育研究项目组”组长，从事计算机专业教育和科学研究30余年，主讲计算机操作系统、嵌入式计算系统及分布式计算系统等课程，曾任教育部计算机教学指导委员会委员，对计算机类专业教育有深刻的理解和深入研究。主持的“国际化工程型软件人才培养”获得国家教学成果二等奖，主持完成近10项网络化嵌入式计算系统、信息物理融合系统等国家级课题。",
        },
        {
          img: "",
          name: "老师二",
          title: "西北工业大学教授，博士生导师",
          dec: "教育部高等学校计算机类专业教学指导委员“智能时代计算机类专业教育研究项目组”组长，从事计算机专业教育和科学研究30余年，主讲计算机操作系统、嵌入式计算系统及分布式计算系统等课程，曾任教育部计算机教学指导委员会委员，对计算机类专业教育有深刻的理解和深入研究。主持的“国际化工程型软件人才培养”获得国家教学成果二等奖，主持完成近10项网络化嵌入式计算系统、信息物理融合系统等国家级课题。",
        },
        {
          img: "",
          name: "老师三",
          title: "西北工业大学教授，博士生导师",
          dec: "教育部高等学校计算机类专业教学指导委员“智能时代计算机类专业教育研究项目组”组长，从事计算机专业教育和科学研究30余年，主讲计算机操作系统、嵌入式计算系统及分布式计算系统等课程，曾任教育部计算机教学指导委员会委员，对计算机类专业教育有深刻的理解和深入研究。主持的“国际化工程型软件人才培养”获得国家教学成果二等奖，主持完成近10项网络化嵌入式计算系统、信息物理融合系统等国家级课题。",
        },
      ],
    };
  },
  mounted(){
    this.bigName = window.localStorage.getItem("xtnlds-anjing");
  },
  methods: {
    getImgUrl(i) {
      return `${baseUrl}`;
    },
  },
};
</script>
  <style scoped>
  .bread {
  background: #ffeed5;
  padding: 5px 10px;
  border-radius: 7px;
  margin-bottom:100px;
}
/* For demo */
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
/deep/.ant-carousel[data-v-334229df] .slick-thumb{
    bottom: -90px;
}
/deep/.ant-carousel[data-v-334229df] .slick-thumb li img{
    width:88%;
}
</style>
  